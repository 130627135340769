import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

// 问题管理 列表
export function GetBannerIndex(data) {
  return request({
    url: '/backend/web/problems/index',
    method: GET,
    params:data
  });
}

// 问题状态改变
export function PostBannerState(data) {
  return request({
    url: '/backend/web/problems/state',
    method: POST,
    data
  });
}

// 问题详情
export function GetProblemDetail(data) {
  return request({
    url: '/backend/web/problems/detail',
    method: GET,
    params:data
  });
}

// 问题删除
export function PostBannerDelete(data) {
  return request({
    url: '/backend/web/problems/delete',
    method: POST,
    data
  });
}

// 问题新增
export function PostBannerCreate(data) {
  return request({
    url: '/backend/web/problems/create',
    method: POST,
    data
  });
}

// 问题编辑
export function PostBannerUpdate(data) {
  return request({
    url: '/backend/web/problems/update',
    method: POST,
    data
  });
}

// 问题更改排序
export function PostBannerSort(data) {
  return request({
    url: '/backend/web/problems/sort',
    method: POST,
    data
  });
}

// 上传临时文件
export function PostBannerTempUpload(data) {
  return request({
    url: '/backend/web/banner/temp-upload',
    method: POST,
    data
  });
}

