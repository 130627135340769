<template>
  <NTooltip trigger="hover" @update:show="suspension">
    <template #trigger>
      <img :src="src1"  class="icons-bh" v-if="!issrc"/>
      <img :src="src2"  class="icons-bh" v-else/>
    </template>
    <span> {{title}} </span>
  </NTooltip>
</template>

<script>
import { ref, defineComponent } from "vue";
import { NTooltip } from 'naive-ui'

export default defineComponent({
  // 父组件传来的值
  props: {
    src1: String,
    src2: String,
    title: String
  },
  setup(props, context) {
    const { attrs, slots, emit } = context;
    const src1 = { ...props }.src1; // 原来的图标
    const src2 = { ...props }.src2; // 悬浮后图标
    const title = { ...props }.title; // 提示文字
    const issrc = ref(false)
    return {
      issrc,
      title:ref(title),
      // 跳转修改密码
      suspension() {
        if(!issrc.value){
          issrc.value = true
        }else{
          issrc.value = false
        }
      },
    };
  },
});
</script>
<style lang="less" scoped>
.icons-bh{
  max-width: 18px;
  max-height: 18px;
  overflow: hidden;
}
// @primary-color: #538fff;
// @success-color: #49dcbb;
// @warning-color: #ffc55f;
// @error-color: #ff8784;
// @tips-color: #b4b4b4;

// @page-background: #f4f7fe;

// @main-text-color: #102e66;
// @sub-text-color: #9cb2cd;
</style>